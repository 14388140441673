<template>
    <v-dialog v-model="closeData.model" width="auto">
        <v-card>
            <v-col align=center>
                <div class="my-2" style="font-weight: bold;">
                    {{ closeData.label }}
                </div>
                <v-row>
                    <v-col>
                        <v-btn class="button_armo" style="background-color: red; color: white;" @click="closeData.model = false">
                            Non
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn class="button_armo" style="background-color: green; color: white;" @click="closeData.callback">
                            OUi
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-dialog>
    <v-dialog v-model="modalModel['isPropositionSelectionCreationGroupOpenDelete']" width="auto">
        <v-card>
            <v-col align=center>
                <div class="my-2" style="font-weight: bold;">
                    Valider la suppression
                </div>
                <v-row>
                    <v-col>
                        <v-btn class="button_armo" style="background-color: red; color: white;"
                            @click="modalModel['isPropositionSelectionCreationGroupOpenDelete'] = false">
                            Non
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn class="button_armo" style="background-color: green; color: white;" @click="deleteCategory">
                            OUi
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-dialog>
    <v-dialog v-model="modalModel['isPropositionSelectionCreationGroupOpen']" width="99%" max-width="800">
        <v-card class="pa-2" align=center style="position: relative;">
            <v-btn @click="modalModel['isPropositionSelectionCreationGroupOpen'] = false" icon="mdi-close" color="red"
                style="position: absolute; right: 10px; top:10px" density="compact">
            </v-btn>
            <div align=center class="my-3" style="font-weight: bold;color: var(--C1);font-size: 25px;">
                Gestion des catégories
            </div>
            <v-divider class="mb-2"></v-divider>
            <v-row>
                <v-col cols="">
                    <v-text-field label="Nom de la catégorie" v-model="newCategory['label']" hide-details class="bg"
                        style="color:white"></v-text-field>
                </v-col>
                <v-col cols="auto">
                    <v-btn icon="mdi-delete" color="red" variant="text"
                        @click="modalModel['isPropositionSelectionCreationGroupOpenDelete'] = true">
                    </v-btn>
                </v-col>
            </v-row>
            <div align=left style="width: 100%;" class="bg pa-2 mt-2">
                <h3 style="color:white">
                    Couleur de la catégorie
                </h3>
                <v-color-picker :swatches="swatches" v-model="newCategory['color']" show-swatches elevation="0" style=""
                    width="800"></v-color-picker>
            </div>
            <v-col align=center>
                <v-btn @click="addNewCategory" class="button">
                    <div v-if="newCategory.id == undefined">
                        Ajouter ma catégorie
                    </div>
                    <div v-else>
                        Valider mes modifications
                    </div>
                </v-btn>
            </v-col>
        </v-card>
    </v-dialog>
    <v-dialog v-model="modalModel['isPropositionSelectionGroupOpen']" width="99%" max-width="600">
        <v-card class="pa-2 py-4" align=center style="position: relative;">
            <v-btn @click="modalModel['isPropositionSelectionGroupOpen'] = false" icon="mdi-close" color="red"
                style="position: absolute; right: 10px; top:10px" density="compact">
            </v-btn>
            <div align=center class="my-3" style="font-weight: bold;color: var(--C1);font-size: 25px;">
                Liste des catégories <v-btn class="ml-3 button" style="border-radius: 100% !important;"
                    density="compact" icon="mdi-plus"
                    @click="modalModel['isPropositionSelectionCreationGroupOpen'] = true">
                </v-btn>
            </div>
            <v-divider class="mb-2"></v-divider>
            <v-col cols="12" align=center class="py-0">
                <v-card variant="flat" class="bg py-2">
                    <v-col cols="10" class="px-0">
                        <v-row justify="left" align=center>
                            <v-col v-for="group in localLocalSelectedSite.chatgpt_proposal_group" :key="group.id"
                                class="py-1 px-2" cols="auto">
                                <v-card :color="group.color" class="py-2 px-6" variant="flat"
                                    style="border-radius: 15px; color:white" @click="editCategory(group)">
                                    {{ group.label }}
                                    <!-- <v-icon size="20">mdi-pencil-outline</v-icon> -->
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-card>
            </v-col>
        </v-card>
        <!-- {{ localLocalSelectedSite.chatgpt_proposal_group }} -->
    </v-dialog>
    <v-dialog v-model="modalModel['isDeleteProposalOpen']" width="auto">
        <v-card>
            <v-col align=center>
                <div class="my-2" style="font-weight: bold;">
                    Valider la suppression
                </div>
                <v-row>
                    <v-col>
                        <v-btn class="button_armo" style="background-color: red; color: white;"
                            @click="modalModel['isDeleteProposalOpen'] = false">
                            Non
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn class="button_armo" style="background-color: green; color: white;" @click="deleteValidation">
                            OUi
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-dialog>
    <v-dialog v-model="modalModel['isPropositionNoteOpen']" width="99%" max-width="800">
        <v-card class="pa-2" align=center style="position: relative;">
            <v-btn @click="modalModel['isPropositionNoteOpen'] = false" icon="mdi-close" color="red"
                style="position: absolute; right: 10px; top:10px" density="compact">
            </v-btn>
            <div align=center class="my-3" style="font-weight: bold;color: var(--C1);font-size: 25px;">
                Article de blog
            </div>
            <v-divider class="mb-2"></v-divider>
            <v-textarea label="Titre de l'article de blog" style="color: white;border-radius: 5px;" rows="1" class="mb-2  bg"
                hide-details v-model="selectedProposition.title" auto-grow></v-textarea>
            <v-card class="mt-2 bg" variant="flat">
                <v-select label="Catégorie" :items="localLocalSelectedSite.chatgpt_proposal_group"
                    v-model="selectedProposition.category" multiple hide-details item-title="label" item-value="id"
                    style="color:white" no-data-text="Aucune catégorie trouvée">
                </v-select>
            </v-card>
            <v-textarea label="Note" rows="1" style="color: white;border-radius: 5px;" class="bg mt-4" hide-details
                v-model="selectedProposition.note" auto-grow></v-textarea>
            <!-- <v-divider></v-divider> -->
            <v-col align="center" cols="12">
                <v-btn v-if="isNewItem" class="mt-2 pa-2 button" width="" variant="outlined" style=""
                    :disabled="!(selectedProposition.title && selectedProposition.title.length > 0)"
                    @click="addProposition">
                    Ajouter mon nouvel article
                </v-btn>
            </v-col>
        </v-card>
    </v-dialog>
    <v-col class="pa-0" cols="12">

        <v-card style="position: relative;" class="pa-2" align=center>
            <v-btn style="position: absolute;right: 10px" icon="mdi-close" color="red" density="compact"
                @click="closeModalVerif"></v-btn>

            <!-- <v-btn style="position: absolute;left: 10px;border-radius: 100% !important;" variant="flat" class="button"
    height="35" width="35" icon="mdi-cog" density="compact"
    @click="modalModel['isPropositionSelectionGroupOpen'] = true"></v-btn> -->
            <div align=center class="my-3" style="color: var(--C1); font-weight: bold;font-size: 25px;">
                Gestion d'articles de blog
                <!-- <v-btn class="ml-3 button" style="border-radius: 100% !important;"
        density="compact" icon="mdi-plus" @click="newItem">
    </v-btn> -->
            </div>
            <v-divider></v-divider>
            <div>
                <v-col class=px-0 align="left">
                    <v-btn class="button" variant="flat" style="font-weight: bold;"
                        @click="modalModel['isPropositionSelectionGroupOpen'] = true">
                        <v-span v-if="!isMobile" class="mr-3"> Gestion des catégories </v-span>
                        <v-icon class="">mdi-cog</v-icon>
                    </v-btn>
                    <v-btn class="ml-2 button" variant="flat" style="font-weight: bold;" @click="newItem">                        
                        <v-span v-if="!isMobile" class="mr-2"> Ajouter un article de blog </v-span>
                        <v-icon >mdi-plus-thick</v-icon>
                    </v-btn>
                </v-col>
            </div>
            <v-col cols="12" class="px-0 pt-0">
                <v-text-field style="border: solid 1px var(--C1);" label="Recherche..."
                    v-model="propositionSearch" hide-details > </v-text-field>
            </v-col>

            <v-card class="bg py-2 pt-3" style="border-radius: 0px;">
                <v-col class="pl-5 pr-5 py-2">
                    <v-row justify="center" align=center class="" style="background-color: white;">
                        <v-col v-if="!isMobile" cols="1" class="px-0"
                            style="color:var(--C1); font-weight: bold; background-color: white" align="center">
                            <!-- <v-card align=center class="pa-3" style="font-weight: bold;"> -->
                            Position
                            <!-- </v-card> -->
                        </v-col>
                        <v-col cols="9" align="center" class="" style="background-color: white;">
                            <v-card align=left class="" style="font-weight: bold;height: 100%;" color="transparent"
                                variant="flat">
                                <div style="color:var(--C1);">
                                    Titre de l'article
                                </div>
                            </v-card>
                        </v-col>
                        <v-col v-if="!isMobile" :cols="isMobile ? '3' : '2'" align="center" class=""
                            style="color:var(--C1); font-weight: bold;">
                            <!-- <v-card align=center class="pa-2" style="font-weight: bold;"> -->
                            Modifications
                            <!-- </v-card> -->
                        </v-col>
                    </v-row>
                </v-col>
                <v-divider></v-divider>
                <Draggable v-if="localPreselectArticleListe && localPreselectArticleListe.length > 0
                    && modalModel['isPropositionNoteOpen'] == false
                    && modalModel['isPropositionSelectionCreationGroupOpenDelete'] == false
                    && modalModel['isDeleteProposalOpen'] == false
                    && modalModel['isPropositionSelectionGroupOpen'] == false
                " v-model="localPreselectArticleListe" style="height: 50vh;border-radius: 5px; overflow-y: auto"
                    class="">
                    <template v-slot:item="{ item }">
                        <v-col class="px-2 pt-0 pb-0" style="border-bottom:solid 1px var(--C1)"
                            v-if="(propositionSearch && propositionSearch.length == 0) || (item.title.toLowerCase().includes(propositionSearch.toLowerCase()))">
                            <v-card class="hover-border" variant="flat" style="cursor: pointer;border-radius: 0px;">
                                <v-col class="">
                                    <v-row style="" justify="center" align=center class="">
                                        <v-col v-if="!isMobile" cols="1" class="px-0" align="center" style="">
                                            <span
                                                style="background-color: var(--C1);border-radius: 100%;color: white;font-weight: bold;border: solid 1px #3949AB;"
                                                class="py-2 px-3">
                                                {{ localPreselectArticleListe.findIndex((val) => val.id == item.id)
                                                    + 1
                                                }}
                                            </span>
                                        </v-col>
                                        <v-divider vertical></v-divider>
                                        <v-col cols="9" style="font-weight: bold;" class="" align="left">
                                            <div style="font-size: 13px;">
                                                {{ item.title }}
                                            </div>
                                            <span v-if="item.note && item.note.length > 0" style="font-size: 13px;">
                                                ( {{ item.note }} )
                                            </span>
                                            <v-col class="px-2" v-if="item.category && item.category.length > 0">
                                                <v-row>
                                                    <v-col v-for="(categoryId, idx) in item.category" :key="idx"
                                                        cols="auto" class="pa-1">
                                                        <v-card v-if="localLocalSelectedSite.chatgpt_proposal_group.
                                                            find((val) => val.id == categoryId)" :color="localLocalSelectedSite.chatgpt_proposal_group.
                                                                find((val) => val.id == categoryId).color"
                                                            class="px-2 py-1" style="font-size:10px">
                                                            <v-card class="px-1"
                                                                style="color:black;background-color:rgba(255,255,255,0.7)"
                                                                variant="flat">
                                                                {{
                                                                    localLocalSelectedSite.chatgpt_proposal_group.
                                                                        find((val) => val.id == categoryId).label }}
                                                            </v-card>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-col>
                                        <v-divider vertical></v-divider>
                                        <v-col :cols="isMobile ? '3' : '2'" class="">
                                            <v-row justify="center" align="center">
                                                <v-col align=center>
                                                    <v-btn @click="setPropositionEdition(item)" color="white"
                                                        class="buttonReverse" variant="flat">
                                                        <v-icon color=green size="20">
                                                            mdi-note
                                                        </v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-divider vertical></v-divider>
                                                <v-col align=center>
                                                    <v-btn @click="deletProposalFromId(item.id)" color="white"
                                                        variant="flat" class="buttonReverse">
                                                        <v-icon color=red size="20">
                                                            mdi-delete
                                                        </v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-card>
                        </v-col>
                    </template>
                </Draggable>
                <div v-else style="height: 67vh;border-radius: 5px; overflow-y: auto" class="bg">

                </div>
            </v-card>
            <v-divider class="mt-3"></v-divider>
            <v-col align=center>
                <v-btn class="button" @click="localUpdateNewProposal">
                    Valider mes modifications
                </v-btn>
            </v-col>
        </v-card>
    </v-col>
</template>

<script>

import Draggable from "vue3-draggable";

import {
    // watch,
    ref,
    onMounted
} from 'vue'

import {
    v4 as uuidv4
} from 'uuid';

export default {
    components: {
        Draggable
    },
    props: {
        updateNewProposal: { type: Function },
        sendNewConfig: { type: Function },
        closeModal: { type: Function },
        localSelectedSite: {},
        preselectArticleListe: {},
        groupInformation: {}
    },
    data() {
        return {
            selectedProposition: {},
            closeData: {},
            newCategory: {},
            propositionSearch: "",
            isNewItem: false,
            swatches: [
                ['#FF0000', '#AA0000', '#550000'],
                ['#FFFF00', '#AAAA00', '#555500'],
                ['#00FF00', '#00AA00', '#005500'],
                ['#00FFFF', '#00AAAA', '#005555'],
                ['#0000FF', '#0000AA', '#000055'],
            ],
        }
    },
    setup(props) {
        const modalModel = ref({})
        const selectedIndyById = ref(undefined);
        const localPreselectArticleListe = ref([])
        const localLocalSelectedSite = ref([])

        const deletProposalFromId = function (id) {
            modalModel.value["isDeleteProposalOpen"] = true
            selectedIndyById.value = localPreselectArticleListe.value.findIndex((val) => val.id == id)
        };

        const deleteValidation = function () {
            localPreselectArticleListe.value.splice(selectedIndyById.value, 1)
            selectedIndyById.value = undefined
            modalModel.value["isDeleteProposalOpen"] = false
        }

        const initData = function () {
            localPreselectArticleListe.value = JSON.parse(JSON.stringify(props.preselectArticleListe))
            localLocalSelectedSite.value = JSON.parse(JSON.stringify(props.localSelectedSite))
        }

        onMounted(() => {
            initData()

            modalModel.value["isDeleteProposalOpen"] = false
            modalModel.value["isPropositionNoteOpen"] = false
            modalModel.value["isPropositionSelectionCreationGroupOpenDelete"] = false
            modalModel.value["isPropositionSelectionGroupOpen"] = false
        })

        return {
            modalModel,
            deletProposalFromId,
            deleteValidation,
            localPreselectArticleListe,
            localLocalSelectedSite,
        }
    },
    methods: {
        closeModalVerif() {
            let that = this
            this.setCloseModal(
                'Aucune modification ne sera sauvegardée',
                function () {
                    that.closeModal()
                    that.closeData.model = false
                }
            )
        },
        setCloseModal(label, callback) {
            this.closeData = {
                label,
                model: true,
                callback
            }
        },
        localUpdateNewProposal() {
            let that = this
            this.setCloseModal("L'ensemble de mes modifications vont être enregistrées",
                function () {
                    that.updateConfigsByKey('chatgpt_proposal_group', that.localLocalSelectedSite.chatgpt_proposal_group)
                    that.updateNewProposal(that.localPreselectArticleListe)
                    that.closeData.model = false
                }
            )
        },
        updateConfigsByKey(keyConfig, newConfig) {
            let localGroupInformation = JSON.parse(JSON.stringify(this.groupInformation))
            let key = this.localLocalSelectedSite.platform


            for (let i = 0; i < localGroupInformation.web_site_data[key].length; i++) {
                let elem = localGroupInformation.web_site_data[key][i]
                if (elem.blog_id == this.localLocalSelectedSite.blog_id) {
                    elem[keyConfig] = newConfig
                }
            }

            this.sendNewConfig(localGroupInformation)
        },
        addProposition() {
            this.localPreselectArticleListe.push(this.selectedProposition)
            this.modalModel["isPropositionNoteOpen"] = false
        },
        setPropositionEdition(item) {
            this.isNewItem = false
            this.selectedProposition = item
            this.modalModel["isPropositionNoteOpen"] = true
        },
        deleteCategory() {
            let index = this.localLocalSelectedSite.chatgpt_proposal_group.findIndex((val) => val.id == this.newCategory['id'])
            this.localLocalSelectedSite.chatgpt_proposal_group.splice(index, 1);
            this.modalModel["isPropositionSelectionCreationGroupOpenDelete"] = false
            this.modalModel["isPropositionSelectionCreationGroupOpen"] = false
            this.updateConfigsByKey('chatgpt_proposal_group', this.localLocalSelectedSite.chatgpt_proposal_group)
        },
        editCategory(elem) {
            this.newCategory = elem
            this.modalModel["isPropositionSelectionCreationGroupOpen"] = true
        },
        addNewCategory() {
            if (this.localLocalSelectedSite.chatgpt_proposal_group == undefined)
                this.localLocalSelectedSite.chatgpt_proposal_group = []
            if (this.newCategory['id'] == undefined) {
                this.newCategory['id'] = uuidv4()
                this.localLocalSelectedSite.chatgpt_proposal_group.push(this.newCategory)
            } else {
                let index = this.localLocalSelectedSite.chatgpt_proposal_group.findIndex((val) => val.id == this.newCategory['id'])
                if (index)
                    this.localLocalSelectedSite.chatgpt_proposal_group[index] = this.newCategory
                // else {
                //     this.newCategory['id'] = uuidv4()
                //     this.localLocalSelectedSite.chatgpt_proposal_group.push(this.newCategory)
                // }
            }
            this.newCategory = {}
            this.updateConfigsByKey('chatgpt_proposal_group', this.localLocalSelectedSite.chatgpt_proposal_group)
            this.modalModel["isPropositionSelectionCreationGroupOpen"] = false
        },
        newItem() {
            this.isNewItem = true
            this.selectedProposition = { id: uuidv4() }
            this.modalModel["isPropositionNoteOpen"] = true
            this.localPreselectArticleListe = JSON.parse(JSON.stringify(this.localPreselectArticleListe))
        },
    },
    computed: {
        isMobile() {
            return this.$vuetify.display.mobile
        },
    }
}
</script>